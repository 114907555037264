html {
  font-size: 10px; }
  @media screen and (min-width: 667px) {
    html {
      font-size: 8px; } }
  @media screen and (min-width: 1024px) {
    html {
      font-size: 6px; } }
  @media screen and (min-width: 1280px) {
    html {
      font-size: 7px; } }
  @media screen and (min-width: 1366px) {
    html {
      font-size: 9px; } }
  @media screen and (min-width: 1680px) {
    html {
      font-size: 10px; } }

* {
  padding: 0;
  margin: 0;
  border: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  color: #4B6584;
  font-family: "Raleway", sans-serif;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.no-scroll {
    overflow: hidden; }

input,
button,
textarea,
optgroup,
select {
  font-family: "Raleway";
  font-size: inherit; }

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit; }

picture {
  display: block; }

p {
  margin: 0;
  padding: 0; }

a {
  cursor: pointer;
  display: inline-block;
  color: inherit; }

a:link,
a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0; }

blockquote {
  margin: 0;
  padding: 0; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.main {
  flex: 1 1 auto;
  padding-top: 10rem; }
  @media screen and (max-width: 666px) {
    .main {
      padding-top: 6rem; } }

[class*="__container"] {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 1.5rem; }

@font-face {
  font-family: "Raleway";
  src: url("/static/fonts/Raleway-Regular.woff") format("woff"), url("/static/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Raleway";
  src: url("/static/fonts/Raleway-Medium.woff") format("woff"), url("/static/fonts/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Raleway";
  src: url("/static/fonts/Raleway-SemiBold.woff") format("woff"), url("/static/fonts/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Raleway";
  src: url("/static/fonts/Raleway-Bold.woff") format("woff"), url("/static/fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Raleway";
  src: url("/static/fonts/Raleway-Black.woff") format("woff"), url("/static/fonts/Raleway-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Lato";
  src: url("/static/fonts/Lato-Medium.woff") format("woff"), url("/static/fonts/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }
