@import '../../app/variable.scss';
// щоб стилі кнопки примінялись імпортуємо в scss файл стилі кнопки[@import "../components/button/button.scss";]
.button {
	border: none;
	padding: 2.2rem 2.6rem;
	color: $light;
}

.button > * {
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 2.9rem;
}
.link > * {
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 2.9rem;
}
//@@include( 'develop/components/button/button.html', {
//"title": "вход в Личный кабинет",
//"mod": "test__btn",
//"isButton": "button",
//"href": "login.html",
//"type": "button",
//"img": "@zz/time.svg",
//"alt": "",
//"dataName":"",
//"dataValue":""
//})
