@import '../../app/variable.scss';
.breadcrumbs {
   padding-top: 1.2rem;
   & > * {
      display: flex;
      align-items: center;
      & > * {
         position: relative;
         &:not(:last-child) {
            margin-right: 2rem;
            &:hover,
            &:focus {
               & > * {
                  color: $accent;
               }
            }
            &::after {
               content: "/";
               position: absolute;
               top: 50%;
               right: -1.3rem;
               transform: translate(0, -50%);
               color: $primary-text-color;
               transition: color .25s $time-distribution-function;
            }
            & > * {
               font-size: 1.2rem;
               line-height: 1.4rem;
               color: $title-color;
               transition: color .25s $time-distribution-function;
            }
         }
         & > * {
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $breadcrumbs;
            transition: color .25s $time-distribution-function;
         }
      }
   }
}


