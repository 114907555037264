@import '../../app/variable.scss';

.form {
   width: 100%;
   display: flex;
   flex-direction: column;
   &__wrapper {
      background: $light;
      max-width: 57rem;
      width: 100%;
      padding: 5rem 3rem;
      box-shadow: 1rem 1rem 3rem rgba(6, 82, 221, 0.1);
      @media screen and (max-width: 666px) {
         padding: 2.5rem 1.5rem;
      }
      @media screen and (min-width: 667px) and (max-width: 992px) {
         position: relative;
         z-index: 1;
      }
   }
   &__title {
      padding-bottom: 2rem;
      border-bottom: solid 1px #E6EEFC;
      & > * {
         font-weight: 700;
         font-size: 2rem;
         line-height: 2.3rem;
         color: $title-color;
      }
   }
   &__icon {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translate(0, -50%);
      width: 1.8rem;
      height: 1.8rem;
      & > * {
         width: 1.8rem;
         height: 1.8rem;
      }
   }
   &__icon-textarea {
      position: absolute;
      top: 2.5rem;
      left: 2.4rem;
      width: 1.8rem;
      height: 1.8rem;
      & > * {
         width: 1.8rem;
         height: 1.8rem;
      }
   }
   &__list {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         flex-wrap: wrap;
      }
   }
   &__item {
      position: relative;
      &:not(:last-child) {
         margin-bottom: 3rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
      &:nth-child(1) {
         flex: 0 1 calc(100% / 2 - 1.5rem);
      }
      &:nth-child(2) {
         flex: 0 1 calc(100% / 2 - 1.5rem);
      }
      &:nth-child(3) {
         flex: 0 1 100%;
      }
      &:nth-child(4) {
         flex: 0 1 calc(100% / 2 - 1.5rem);
      }
      &:nth-child(5) {
         flex: 0 1 calc(100% / 2 - 1.5rem);
      }
      &:nth-child(6) {
         flex: 0 1 100%;
      }
      &.error {
         & input {
            background: #FEFAFA;
            border: 1px solid #FAE6E6;
            & ~ span {
               font-family: $family_Lato, sans-serif;
               font-weight: 500;
               font-size: 1.2rem;
               line-height: 1.4rem;
               color: #CE0000;
               opacity: 1;
            }
         }
         & textarea {
            background: #FEFAFA;
            border: 1px solid #FAE6E6;
            & ~ span {
               font-family: $family_Lato, sans-serif;
               font-weight: 500;
               font-size: 1.2rem;
               line-height: 1.4rem;
               color: #CE0000;
               opacity: 1;
            }
         }
      }
      & input {
         width: 100%;
         height: 7.2rem;
         padding: 3.6rem 1.2rem 1.5rem 6.2rem;
         font-size: 1.6rem;
         line-height: 1.9rem;
         background: $bg-color;
         color: $title-color;
         outline: none;
         &::placeholder {
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: $primary-text-color;
            opacity: 0;
            transition: opacity .25s $time-distribution-function;
         }
         &:focus {
            &::placeholder {
               opacity: 0.5;
            }
         }
         & ~ span {
            position: absolute;
            right: 0;
            bottom: -1.7rem;
            opacity: 0;
         }
         &:focus + label {
            font-family: $family_Lato;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $title-color;
            opacity: 1;
            transform: translate(0, -2.2rem);
            & span {
               color: $accent;
               display: block;
            }
         }
         &:not(:placeholder-shown) + label:not(:nth-last-child(1)) {
            font-family: $family_Lato;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $title-color;
            transform: translate(0, -2.2rem);
            opacity: 1;
            & span {
               display: block;
            }
         }
      }
      & textarea {
         outline: none;
         &::placeholder {
            font-size: 1.8rem;
            line-height: 2.1rem;
            opacity: 0.5;
            color: $primary-text-color;
         }
         & ~ span {
            position: absolute;
            right: 0;
            bottom: -1.7rem;
            opacity: 0;
         }
      }
      & label {
         display: flex;
         align-items: center;
         position: absolute;
         top: 50%;
         left: 6.2rem;
         transform: translate(0, -50%);
         font-weight: 500;
         font-size: 1.6rem;
         line-height: 1.9rem;
         opacity: 0.5;
         color: $primary-text-color;
         transition: color 250ms $time-distribution-function, transform 250ms $time-distribution-function, opacity 250ms $time-distribution-function;
         & span {
            color: $accent;
            display: none;
         }
      }
   }
   &__textarea {
      width: 100%;
      height: 18.6rem;
      padding: 2.5rem 1.2rem 1.2rem 6.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      background: $bg-color;
      color: $title-color;
      background: $bg-color;
      resize: none;
      &::placeholder {
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $primary-text-color;
      }
   }
   &__btn {
      @extend %link;
      margin-top: 3rem;
      align-self: center;
   }
}

.form-success {
   max-width: 84rem;
   width: 100%;
   display: none;
   &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem 2rem 6rem;
      @media screen and (max-width: 666px) {
         padding: 3rem 2rem;
      }
   }
   &__title {
      &:not(:last-child) {
         margin-bottom: 1.6rem;
      }
      & > * {
         font-weight: 700;
         font-size: 2rem;
         line-height: 2.3rem;
         color: $title-color;
      }
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 3rem;
      }
      & > * {
         font-size: 1.4rem;
         line-height: 1.6rem;
         text-align: center;
      }
      &_bold {
         & > * {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: #202020;
         }
      }
   }
   &__btn {
      min-width: 14rem;
      padding: 1.8rem;
      text-align: center;
      background-color: $accent;
      transition: background-color .25s $time-distribution-function;
      box-shadow: 1rem 1rem 4.5rem rgba(6, 82, 221, 0.4);
      outline: none;
      @media screen and (max-width: 666px) {
         min-width: 18rem;
         padding: 1rem;
      }
      &:hover {
         background-color: $hover-accent-active;
      }
      & > * {
         font-weight: 500;
         font-size: 1.6rem;
         line-height: 1.9rem;
         color: $light;
      }
   }
   &__img {
      max-width: 84rem;
      width: 100%;
      height: 16rem;
   }
}