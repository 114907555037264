@import '../app/variable.scss';
@import '../app/placeholder.scss';
//@import "../components/select-dropdown/select-dropdown.scss"; щоб були видні стилі

/* ============================ index START ================================================================= */
.potential {
   position: relative;
   background-color: $bg-color;
   &__container {
      display: flex;
      align-items: center;
      padding: 14rem 1.5rem;
      @media screen and (max-width: 666px) {
         flex-direction: column;
         padding: 1.5rem 1.5rem 3rem;
      }
      @media screen and (min-width: 667px) {
         justify-content: space-between;
      }
   }
   &__content {
      position: relative;
      max-width: 57rem;
      width: 100%;
      flex: 0 0 50%;
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 1rem;
      }
   }
   &__slider {
      max-width: 90rem;
      width: 100%;
      min-width: 0; //bug fix
      @media screen and (max-width: 666px) {
         max-width: 60rem;
      }
      @media screen and (min-width: 667px) {
         flex: 0 1 50%;
      }
      @media screen and (min-width: 1024px) {
         transform: translate(28%, 0);
      }
   }
   &__sub-title {
      @extend %sub-title;
      & > * {
         @media screen and (max-width: 666px) {
            font-size: 1.3rem;
            line-height: 1.5rem;
         }
      }
      @media screen and (max-width: 666px) {
         padding: 1rem;
      }
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
   }
   &__title {
      margin-bottom: 3rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 1.5rem;
      }
      & > * {
         font-weight: 500;
         font-size: 6rem;
         line-height: 7rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 4rem;
         }
      }
   }
   &__text {
      @media screen and (max-width: 666px) {
         margin-bottom: 1.5rem;
      }
      @media screen and (min-width: 667px) {
         margin-bottom: 3rem;
      }
      & > * {
         font-size: 1.8rem;
         line-height: 2.5rem;
         @media screen and (max-width: 666px) {
            font-size: 1.4rem;
            line-height: 2rem;
         }
      }
   }
}

.slider-potential {
   @media screen and (max-width: 666px) {
      height: 20rem;
   }
   &__img {
      @media screen and (max-width: 666px) {
         height: 100%;
      }
      & > * {
         object-fit: contain;
      }
   }
   &__pagination {
      bottom: 1rem !important;
      z-index: 2;
      @media screen and (min-width: 667px) {
         display: none;
      }
   }
   &__navigation-buttons {
      display: inline-flex;
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
   &__wrapper {
      align-items: center;
   }
   &__button-prev,
   &__button-next {
      position: static;
      width: 4rem;
      height: 4rem;
      margin: 0;
      z-index: 0;
      outline: none;
      &:hover {
         & > * {
            fill: $hover-accent-active;
         }
      }
      & > * {
         fill: $accent;
         transition: fill .25s $time-distribution-function;
      }
      &::after {
         font-size: 0;
      }
   }
   &__icon {
      width: 3rem;
      height: 1.2rem;
      & > * {
         width: 3rem;
         height: 1.2rem;
      }
   }
}

.info {
   &__container {
      padding: 15rem 1.5rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   //&__list {
   //   display: flex;
   //   flex-wrap: wrap;
   //   gap: 3rem;
   //   column-gap: 9rem;
   //   @media screen and (min-width: 667px) and (max-width: 992px) {
   //      column-gap: 3rem;
   //   }
   //   @media screen and (max-width: 666px) {
   //      flex-direction: column;
   //      align-items: center;
   //      gap: 2rem;
   //   }
   //}
   //&__item {
   //   @extend %span-in-text;
   //   display: flex;
   //   flex-direction: column;
   //   width: calc(33.33% - 6rem);
   //   @media screen and (min-width: 667px) and (max-width: 992px) {
   //      width: calc(33.33% - 2rem);
   //   }
   //   @media screen and (max-width: 666px) {
   //      max-width: 33rem;
   //      width: 100%;
   //   }
   //}
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -3rem 0 0 -3rem;
      @media screen and (max-width: 666px) {
         margin: -1.5rem 0 0 -1.5rem;
      }
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      @extend %span-in-text;
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - 3rem);
      margin: 3rem 0 0 3rem;
      @media screen and (max-width: 666px) {
         width: calc(100% / 2 - 1.5rem);
         margin: 1.5rem 0 0 1.5rem;
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2.5rem;
         }
      }
   }
   &__img {
      width: 7rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $bg-color;
      border-radius: 50%;
      margin-bottom: 2rem;
      @media screen and (max-width: 666px) {
         width: 5rem;
         height: 5rem;
      }
   }
   &__icon {
      width: 2.6rem;
      height: 2.6rem;
      @media screen and (max-width: 666px) {
         width: 2rem;
         height: 2rem;
      }
      & > * {
         display: block;
         width: 2.6rem;
         height: 2.6rem;
         @media screen and (max-width: 666px) {
            width: 2rem;
            height: 2rem;
         }
      }

      &_increase {
         width: 3rem;
         height: 2rem;
         @media screen and (max-width: 666px) {
            width: 2.5rem;
            height: 1.5rem;
         }
      }
      &_bolt {
         width: 1.8rem;
         height: 3rem;
      }
   }
}

.motor {
   background-color: $bg-color;
   &__container {
      padding: 10rem 1.5rem 8rem;
      @media screen and (max-width: 666px) {
         padding: 4rem 1.5rem;
      }
   }
   &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 1024px) {
         flex-direction: row;
      }
   }
   &__content {

      width: 100%;
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
         min-width: 50.8rem;
      }
   }
   &__title {
      margin-bottom: 13rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 5rem;
      }
      & > * {
         font-weight: 500;
         font-size: 3.6rem;
         line-height: 4.2rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 3.6rem;
         }
         & > * {
            font-weight: 900;
         }
      }
   }
   &__sub-title {
      @extend %sub-title;
      @media screen and (max-width: 666px) {
         padding: 1rem;
      }
      &:not(:last-child) {
         margin-bottom: 2.4rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 1.5rem;
         }
      }
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 6.7rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
      & > * {
         font-size: 2rem;
         line-height: 3rem;
         @media screen and (max-width: 666px) {
            font-size: 1.5rem;
            line-height: 1.7rem;
         }
      }
   }
   &__img {
      position: relative;
      height: 56.3rem;
      cursor: pointer;
      @media screen and (max-width: 666px) {
         height: auto;
      }
      @media screen and (min-width: 1024px) {
         //min-width: 94.5rem;
         min-width: 84.5rem;
         transform: translateX(9%);
      }
      @media screen and (min-width: 1366px) {
         min-width: 78.5rem;
      }
      @media screen and (min-width: 1680px) {
         min-width: 83.5rem;
      }
      @media screen and (min-width: 1920px) {
         min-width: 94.5rem;
      }
      @media screen and (min-width: 1367px) {
         &:hover {
            & :last-child {
               opacity: 1;
            }
         }
      }
      &.is-hidden {
         & :last-child {
            opacity: 1;
         }
      }
      & > * {
         @media screen and (max-width: 666px) {
            object-fit: contain;
         }
         @media screen and (min-width: 1024px) and (max-width: 1919px) {
            object-fit: contain;
         }

      }
      & :last-child {
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         opacity: 0;
         transition: opacity 0.9s ease;
      }
   }
   &__pulse {
      position: absolute;
      top: 44%;
      left: 56%;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: $accent;
      box-shadow: 0 0 6rem 1rem $accent;
      @media screen and (max-width: 666px) {
         width: 2rem;
         height: 2rem;
      }
      &::before {
         content: "";
         position: absolute;
         inset: 0;
         border: 1px solid $accent;
         border-radius: 50%;
         animation-name: pulse;
         animation-duration: 2s;
         animation-timing-function: linear;
         animation-iteration-count: infinite;
         box-shadow: 0 0 1rem .2rem $accent;
      }
      &::after {
         content: "";
         position: absolute;
         inset: 0;
         border: 1px solid $accent;
         border-radius: 50%;
         animation-name: pulse;
         animation-duration: 2s;
         animation-timing-function: linear;
         animation-iteration-count: infinite;
         animation-delay: 1s;
         box-shadow: 0 0 1rem .2rem $accent;
      }
   }
}

@keyframes pulse {
   0% {
      transform: scale(1);
      opacity: 0;
   }
   10% {
      opacity: 1;
   }
   100% {
      transform: scale(2.3);
      opacity: 0;
   }
}

.growing {
   position: relative;
   padding: 11.5rem 0;
   @media screen and (max-width: 666px) {
      padding: 5rem;
   }
   &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
   }
   &__img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
   }
   &__title {
      margin-bottom: 4rem;
      @media screen and (max-width: 666px) {
         white-space: nowrap;
      }
      & > * {
         font-weight: 500;
         font-size: 4rem;
         line-height: 4.7rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 3.7rem;
         }
         & > * {
            font-weight: 900;
         }
      }
   }
   &__link {
      @extend %link;
   }
}
/* ============================ index END ================================================================= */



