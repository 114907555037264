@import '../app/variable.scss';
@import '../app/placeholder.scss';

/* ============================ company START ================================================================= */
.company-about {
   background: $bg-color;
   position: relative;
   &__wrapper {
      display: flex;
      align-items: center;
      padding: 7.4rem 0 10rem;
      @media screen and (max-width: 666px) {
         flex-direction: column;
         padding: 5rem 0;
      }
      @media screen and (min-width: 667px) and (max-width: 1023px) {
         padding: 3.7rem 0 3rem;
      }
   }
   &__content {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 667px) {
         max-width: 57rem;
         width: 100%;
         margin-right: 1rem;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
   }
   &__title {
      @extend %title;
   }
   &__text {
      & > * {
         font-weight: 400;
         font-size: 1.8rem;
         line-height: 2.5rem;
         & > * {
            font-weight: 700;
            color: $accent;
         }
      }
   }
   &__img {
      @media screen and (min-width: 667px) {
         position: absolute;
         bottom: 0;
         right: 7%;
         max-width: 75.3rem;
         width: 100%;
         height: 47.7rem;
      }
      @media screen and (min-width: 667px) and (max-width: 1023px) {
         position: static;
         max-width: 79.2rem;
         height: 39rem;
         transform: translateX(8%);
      }
      & > * {
         object-fit: contain;
      }
   }
}

.company-info {
   &__container {
      padding: 10rem 1.5rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   &__img {
      width: 7rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $bg-color;
      border-radius: 50%;
      margin-bottom: 2rem;
      @media screen and (max-width: 666px) {
         width: 5rem;
         height: 5rem;
      }
   }
   &__wrapper {
      padding-bottom: 2rem;
      border-bottom: #E6EEFC solid 1px;
      &:not(:last-child) {
         margin-bottom: 2.4rem;
      }
   }
   &__title {

      & > * {
         font-weight: 700;
         font-size: 2rem;
         line-height: 2.3rem;
         color: $title-color;
      }
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 1rem;
      }
      & > * {
         font-size: 1.8rem;
         line-height: 2.5rem;
      }
      &_bold {
         &:not(:last-child) {
            margin-bottom: 1rem;
         }
         & > * {
            font-weight: 700;
            color: $accent;
         }
      }
   }
   &__icon {
      width: 2.6rem;
      height: 2.6rem;
      @media screen and (max-width: 666px) {
         width: 2rem;
         height: 2rem;
      }
      & > * {
         width: 2.6rem;
         height: 2.6rem;
         @media screen and (max-width: 666px) {
            width: 2rem;
            height: 2rem;
         }
      }
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -3rem 0 0 -3rem;
      @media screen and (max-width: 666px) {
         margin: -1.5rem 0 0 -1.5rem;
      }
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      display: flex;
      flex-direction: column;
      padding: 5rem 3rem;
      width: calc(100% / 3 - 3rem);
      margin: 3rem 0 0 3rem;
      box-shadow: 1rem 1rem 3rem rgba(6, 82, 221, 0.1);
      @media screen and (max-width: 666px) {
         padding: 2rem 1.5rem 1.5rem;
         margin: 1.5rem 0 0 1.5rem;
         width: calc(100% / 2 - 1.5rem);
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2.5rem;
         }
      }

   }

}
/* ============================ company END ================================================================= */