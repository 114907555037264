@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ careers START ================================================================= */
.technology {
   position: relative;
   background-color: $bg-color;
   &__wrapper {
      display: flex;
      align-items: center;
      padding: 7.4rem 0 10rem;
      @media screen and (max-width: 666px) {
         flex-direction: column;
         padding: 5rem 0;
      }
   }
   &__content {
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
      @media screen and (min-width: 667px) {
         max-width: 57rem;
         width: 100%;
      }
   }
   &__sub-title {
      @extend %sub-title;
      @media screen and (max-width: 666px) {
         padding: 1rem;
      }
      &:not(:last-child) {
         margin-bottom: 2rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 1rem;
         }
      }
      & > * {
         @media screen and (max-width: 666px) {
            font-size: 1.4rem;
            line-height: 1.7rem;
         }
      }
   }
   &__title {
      &:not(:last-child) {
         margin-bottom: 3rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 1.5rem;
         }
      }
      & > * {
         font-weight: 600;
         font-size: 4rem;
         line-height: 4.7rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 3.7rem;
         }
      }
   }
   &__text {
      & > * {
         font-weight: 600;
         font-size: 1.8rem;
         line-height: 2.5rem;
         color: $title-color;
      }
   }
   &__img {
      @media screen and (min-width: 667px) {
         position: absolute;
         top: 0;
         right: 9%;
         bottom: 0;
         max-width: 95.2rem;
         width: 100%;
         height: 57rem;
      }
      @media screen and (min-width: 667px) and (max-width: 1023px) {
         position: static;
         max-width: 79.2rem;
         height: 39rem;
         transform: translateX(8%);
      }
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
         right: 5%;
      }
      @media screen and (min-width: 1366px) {
         right: 0;
      }
      @media screen and (min-width: 1920px) {
         right: 9%;
      }
      & > * {
         @media screen and (max-width: 1023px) {
            object-fit: contain;
         }
      }
   }
}

.vacancies {
   &__container {
      padding: 10rem 1.5rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   &__title {
      margin-bottom: 6rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
      & > * {
         font-weight: 500;
         font-size: 3.6rem;
         line-height: 4.2rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 3.6rem;
         }
         & > * {
            font-weight: 900;
         }
      }
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -3rem 0 0 -3rem;
      @media screen and (max-width: 666px) {
         margin: -1.5rem 0 0 -1.5rem;
      }
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      display: flex;
      flex-direction: column;
      padding: 4rem 3rem 3rem;
      width: calc(100% / 3 - 3rem);
      margin: 3rem 0 0 3rem;
      box-shadow: 1rem 1rem 3rem rgba(6, 82, 221, 0.1);
      @media screen and (max-width: 666px) {
         padding: 2rem 1.5rem 1.5rem;
         margin: 1.5rem 0 0 1.5rem;
         width: calc(100% / 2 - 1.5rem);
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2.5rem;
         }
      }
   }
   &__wrapper {
      border-top: 1px solid #E6EEFC;
      padding-top: 2rem;
      margin-top: auto;
   }
   &__location {
      @extend %sub-title;
      padding: .8rem 1rem;
      flex: 0 1 auto;
      align-self: baseline;
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      & > * {
         font-size: 1.2rem;
         line-height: 1.4rem;
      }
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      & > * {
         font-weight: 500;
         font-size: 2rem;
         line-height: 3.4rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 1.5rem;
            line-height: 1.7rem;
         }
      }
   }
   &__link {
      margin-top: auto;
      &:hover {
         & > * {
            color: $hover-accent-active;
            fill: $hover-accent-active;
         }
      }
      & > * {
         font-weight: 700;
         font-size: 1.6rem;
         line-height: 1.9rem;
         color: $accent;
         transition: color .25s $time-distribution-function;
      }
   }
   &__icon {
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 0.8rem;
      transform: rotate(-180deg);
      fill: $accent;
      transition: fill .25s $time-distribution-function;
   }
   &__info {
      display: flex;
      justify-content: center;
      background-color: $accent;
      padding: 1rem;
      margin-top: 7rem;
      @media screen and (max-width: 666px) {
         margin-top: 4rem;
      }
      & > * {
         max-width: 50rem;
         width: 100%;
         font-weight: 600;
         font-size: 1.6rem;
         line-height: 1.9rem;
         text-align: center;
         color: $light;
         & > a {
            text-decoration: underline;
            transition: color .25s $time-distribution-function;
            &:hover {
               color: #A5C5FF;
            }
         }
      }
   }
}
/* ============================ careers END ================================================================= */