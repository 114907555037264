@import "variable.scss";
html {
   font-size: 10px;
   @media screen and (min-width: 667px) {
      font-size: 8px;
   }
   @media screen and (min-width: 1024px) {
      font-size: 6px;
   }
   @media screen and (min-width: 1280px) {
      font-size: 7px;
   }
   @media screen and (min-width: 1366px) {
      font-size: 9px;
   }
   @media screen and (min-width: 1680px) {
      font-size: 10px;
   }

}
* {
   padding: 0;
   margin: 0;
   border: 0;
}
*,
*:before,
*:after {
   box-sizing: border-box;
}
:focus,
:active {
   // outline: none;
}
a:focus,
a:active {
   // outline: none;
}
html,
body {
   height: 100%;
}
body {
   margin: 0;
   padding: 0;
   color: $primary-text-color;
   font-family: $family_Raleway, sans-serif;
   //text-rendering: optimizeLegibility;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   &.no-scroll {
      overflow: hidden;
   }
}
input,
button,
textarea,
optgroup,
select, {
   font-family: $family_Raleway;
   font-size: inherit;
}
button {
   cursor: pointer;
   color: inherit;
   background-color: inherit;
}
picture {
   display: block;
}
p {
   margin: 0;
   padding: 0;
}
a {
   cursor: pointer;
   display: inline-block;
   color: inherit;
}
a:link,
a:visited {
   text-decoration: none;
}
a:hover {
   text-decoration: none;
}
ol,
ul,
li {
   list-style: none;
   margin: 0;
   padding: 0;
}
img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
   //vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   font-weight: inherit;
   font-size: inherit;
   padding: 0;
   margin: 0;
}
blockquote {
   margin: 0;
   padding: 0;
}
//прибрати стрілки для input type="number"
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}
.app {
   overflow: hidden;
   display: flex;
   flex-direction: column;
   min-height: 100%;
}
.visually-hidden {
   position: absolute;
   width: 1px;
   height: 1px;
   margin: -1px;
   border: 0;
   padding: 0;
   clip: rect(0 0 0 0);
   overflow: hidden;
}
.main {
   flex: 1 1 auto;
   padding-top: 10rem;
   @media screen and (max-width: 666px) {
      padding-top: 6rem;
   }
}
[class*="__container"] {
   max-width: 120rem;
   margin: 0 auto;
   padding: 0 1.5rem;
}
@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
   $filepath: "/static/fonts/" + $file;
   @font-face {
      font-family: quote($style-name);
      src: url($filepath + ".woff") format('woff'),
         //url($filepath + ".woff2") format('woff2'),
      url($filepath + ".ttf") format('truetype');
      font-weight: $weight;
      font-style: $style;
      font-display: swap;
   }
}

@include font-face($family_Raleway, 'Raleway-Regular', 400, normal);
@include font-face($family_Raleway, 'Raleway-Medium', 500, normal);
@include font-face($family_Raleway, 'Raleway-SemiBold', 600, normal);
@include font-face($family_Raleway, 'Raleway-Bold', 700, normal);
@include font-face($family_Raleway, 'Raleway-Black', 900, normal);

@include font-face($family_Lato, 'Lato-Medium', 500, normal);