@import '../app/variable.scss';
/* ============================ header START ================================================================= */
.header {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 3;
   background-color: $title-color;
   height: 10rem;
   @media screen and (max-width: 666px) {
      height: 6rem;
   }
   &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
   }
   &__logo {
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
   }
   &__icon {
      width: 21.9rem;
      height: 5rem;
      @media screen and (max-width: 666px) {
         width: 17.9rem;
         height: 4rem;
      }
      & > * {
         width: 21.9rem;
         height: 5rem;
         @media screen and (max-width: 666px) {
            width: 17.9rem;
            height: 4rem;
         }
      }
   }
}

.menu {
   @media screen and (max-width: 666px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding-right: 6.5rem;
      z-index: 2;
      transform: translateX(-100%);
      transition: transform .25s $time-distribution-function;
      overflow: auto;
      &.open {
         transform: translateX(0);
      }
   }
   &__wrapper {
      @media screen and (max-width: 666px) {
         padding: 10rem 1.5rem 5rem;
         height: 100%;
         background-color: $title-color;
      }
   }
   &__list {
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__item {
      &:not(:last-child) {
         margin: 0 2rem 0 0;
         @media screen and (min-width: 667px) and (max-width: 768px) {
            margin: 0 1rem 0 0;
         }
         @media screen and (max-width: 666px) {
            margin: 0 0 2rem 0;
         }
      }
      &.active {
         & > * {
            color: $active;
         }
      }
      &:hover {
         & > * {
            color: $hover-accent-active;
         }

      }
      & > * {
         font-weight: 600;
         font-size: 1.4rem;
         line-height: 1.6rem;
         color: $light;
         padding: 1rem;
         text-align: center;
         transition: color .25s $time-distribution-function;
         @media screen and (max-width: 666px) {
            font-size: 2.8rem;
            line-height: 3.4rem;
         }
      }
   }
   &__icon {
      width: 3.3rem;
      height: 3.3rem;
      & > * {
         width: 3.3rem;
         height: 3.3rem
      }
   }
   &__icon-burger {
      &.open {
         display: none;
      }
      width: 3rem;
      height: 3rem;
      & > * {
         width: 3rem;
         height: 3rem;
      }
   }
   &__burger {
      cursor: pointer;
      display: inline-block;
      @media screen and (min-width: 667px) {
         display: none;
      }
   }
   &__close {
      position: absolute;
      top: 3.3rem;
      right: 1.5rem;
      cursor: pointer;
      transition: opacity 1.5s $time-distribution-function;
      @media screen and (min-width: 667px) {
         display: none;
      }
      @media screen and (max-width: 666px) {
         top: 1.3rem;
      }
   }
   &.open {
      transform: translateX(0);
   }
}

.shadow {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 2;
   background: #000000a6;
   opacity: 1;
   transition: opacity .25s $time-distribution-function;
   &.is-hidden {
      opacity: 0;
      pointer-events: none;
   }
}
/* ============================ header END ================================================================= */
