@import '../app/variable.scss';
/* ============================ footer START ================================================================= */
.footer {
   &__container {
      padding: 10rem 1.5rem 4rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem 3rem;
      }
   }
   &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5rem;
      border-bottom: solid 1px #E6EEFC;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__logo {
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
   }
   &__icon {
      width: 21.9rem;
      height: 5rem;
      @media screen and (max-width: 666px) {
         width: 17.9rem;
         height: 4rem;
      }
      & > * {
         width: 21.9rem;
         height: 5rem;
         @media screen and (max-width: 666px) {
            width: 17.9rem;
            height: 4rem;
         }
      }
   }
   &__copyright {
      max-width: 30rem;
      width: 100%;
      margin: 4rem auto 0;
      text-align: center;
      @media screen and (max-width: 666px) {
         margin: 3rem auto 0;
      }
      & > * {
         font-size: 1.4rem;
         line-height: 1.7rem;
      }
   }
}

.menu-footer {
   &__list {
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__item {
      &:not(:last-child) {
         margin: 0 2rem 0 0;
         @media screen and (min-width: 667px) and (max-width: 768px) {
            margin: 0 1rem 0 0;
         }
         @media screen and (max-width: 666px) {
            margin: 0 0 1rem 0;
         }
      }
      &:hover {
         & > * {
            color: $accent;
         }
      }
      & > * {
         font-weight: 600;
         font-size: 1.4rem;
         line-height: 1.6rem;
         color: $title-color;
         padding: 1rem;
         transition: color .25s $time-distribution-function;
      }
   }
}
/* ============================ footer END ================================================================= */
