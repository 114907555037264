$primary-text-color: #4B6584;
$light: #ffffff;
$accent: #0652DD;
$title-color: #1E272E;
$secondary: #101010;
$active: #206FFF;
$hover-accent-active: #5390FF;
$bg-color: #F8FAFE;
$breadcrumbs: #A5B2C1;

$time-distribution-function: cubic-bezier(0.4, 0, 0.2, 1);

$family_Raleway: 'Raleway';
$family_Lato: 'Lato';

