@import "variable.scss";

%sub-title {
   display: inline-block;
   padding: 1rem 1.6rem;
   background: rgba(6, 82, 221, 0.1);
   & > * {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $accent;
      @media screen and (max-width: 666px) {
         font-size: 1.4rem;
         line-height: 1.7rem;
      }
   }
}

%link {
   min-width: 20.7rem;
   padding: 1.8rem;
   text-align: center;
   background-color: $accent;
   transition: background-color .25s $time-distribution-function;
   box-shadow: 1rem 1rem 4.5rem rgba(6, 82, 221, 0.4);
   &:hover {
      background-color: $hover-accent-active;
   }
   & > * {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $light;
   }
}

%span-in-text{
   & > * {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 4.1rem;
      color: $secondary;
      @media screen and (max-width: 666px) {
         font-size: 1.8rem;
         line-height: 3.2rem;
      }
      & > * {
         font-weight: 900;
         color: $accent;
      }
   }
}

%title{
   &:not(:last-child) {
      margin-bottom: 3rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 1.5rem;
      }
   }
   & > * {
      font-weight: 600;
      font-size: 4rem;
      line-height: 4.7rem;
      color: $title-color;
      @media screen and (max-width: 666px) {
         font-size: 3rem;
         line-height: 3.7rem;
      }
   }
}