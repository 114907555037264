@import '../../app/variable.scss';
.service-hero {
	position: relative;
	&__icon{
		fill: grey;
		width: 10rem;
		height: 10rem;
		&:hover,
		&:focus{
		    fill: green;
		}
	}
	.breadcrumbs {
		margin: 0;
		@media screen and (max-width: 480px) {
			margin-bottom: 7rem;
		}
	}

	&__container {
		padding: 2rem 3rem 11rem 3rem;
		min-height: 75rem;
		height: 1px;
		max-width: 170rem;
		width: 100%;
		margin: 0 auto;

		@media screen and (min-width: 1024px) {
			padding: 2rem 0 0 5rem;
		}
	}

	&__inner {
		width: 100%;
		height: calc(100% - 2rem);
		display: flex;
		align-items: center;
	}

	&__wrapper {
		max-width: 100rem;
		width: 100%;

		@media screen and (min-width: 1024px) {
			margin-left: 6rem;
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: .8;
		}

		& > * {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__title {
		margin-bottom: 1rem;

		& > * {
			font-weight: 700;
			font-size: 5.8rem;
			line-height: 3.9rem;

			@media screen and (min-width: 667px) {
				font-size: 4.2rem;
				line-height: 5rem;
			}
		}
	}

	&__text {
		margin-bottom: 3.2rem;

		& > * {
			font-size: 1.4rem;
			line-height: 2.8rem;

			@media screen and (min-width: 667px) {
				font-size: 1.5rem;
				line-height: 3rem;
			}
		}
	}

	.button {
		padding: 1.8rem 2rem;
		transition: background-color .25s $time-distribution-function;

		&:hover,
		&:focus {
		}
	}
}
