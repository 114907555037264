@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ product-range START ================================================================= */
.product {
   background-color: $bg-color;
   &__wrapper {
      display: flex;
      align-items: center;
      @media screen and (min-width: 667px) {
         justify-content: space-between;
         padding: 2.7rem 0 3.6rem;
      }
      @media screen and (max-width: 666px) {
         flex-direction: column;
         padding: 3rem 0;
      }
   }
   &__content {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
         flex: 0 1 50%;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
   }
   &__title {
      @extend %title;
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 1rem;
         & > * {
            color: $primary-text-color;
         }
      }
      & > * {
         font-size: 1.8rem;
         line-height: 2.5rem;
      }
      &_bold {
         & > * {
            font-weight: 600;
            color: $title-color;
         }
      }
   }
   &__img {
      max-width: 47.3rem;
      width: 100%;
      height: 34.8rem;
      @media screen and (min-width: 667px) and (max-width: 1023px) {
         flex: 0 1 50%;
      }
      @media screen and (max-width: 1023px) {
         & > * {
            object-fit: contain;
         }
      }
      @media screen and (min-width: 1024px) {
         transform: translateX(42%);
      }
   }
}

.depending {
   &__container {
      display: flex;
      flex-direction: column;
      padding: 10rem 1.5rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   &__title {
      margin-bottom: 4rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
      & > * {
         font-weight: 500;
         font-size: 2.4rem;
         line-height: 4.1rem;
         color: $secondary;
         & > * {
            font-weight: 900;
            color: $accent;

         }
      }
   }
   &__list {
      display: flex;
      flex-direction: column;
   }
   &__item {
      display: flex;
      align-items: center;
      padding: 3rem 0;
      border-top: solid 1px #E6EEFC;
      & :nth-child(1) {
         flex: 0 1 calc(58% - 1rem);
         font-weight: 600;
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $primary-text-color;
         margin-right: 1rem;
         @media screen and (max-width: 666px) {
            font-size: 1.6rem;
            line-height: 2rem;
         }
      }
      & :nth-child(2) {
         flex: 0 1 42%;
         font-weight: 700;
         font-size: 1.6rem;
         line-height: 1.9rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 1.4rem;
            line-height: 1.8rem;
         }
      }
   }
   &__btn {
      display: flex;
      justify-content: center;
      background-color: $bg-color;
      align-items: center;
      padding: 2rem;
      transition: background-color .25s $time-distribution-function;
      &:hover {
         background: $hover-accent-active;
         & > * {
            color: $light;
            fill: $light;
         }
      }
      & > * {
         font-weight: 700;
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $accent;
         transition: color .25s $time-distribution-function;
      }
   }
   &__icon {
      margin-left: 0.8rem;
      width: 1.4rem;
      height: 1.4rem;
      transform: rotate(-90deg);
      fill: $accent;
      transition: fill .25s $time-distribution-function;
   }
}

.features {
   background: $bg-color;
   &__container {
      padding: 8.5rem 1.5rem 10rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   &__title {
      margin-bottom: 8rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 4rem;
      }
      & > * {
         font-weight: 500;
         font-size: 3.6rem;
         line-height: 4.2rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 3.6rem;
         }
         & > * {
            font-weight: 900;
         }
      }
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -3rem 0 0 -3rem;
      @media screen and (max-width: 666px) {
         margin: -1.5rem 0 0 -1.5rem;
      }
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      @extend %span-in-text;
      display: flex;
      flex-direction: column;
      width: calc(100% / 2 - 3rem);
      margin: 3rem 0 0 3rem;
      @media screen and (max-width: 666px) {
         margin: 1.5rem 0 0 1.5rem;
         width: calc(100% / 2 - 1.5rem);
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
   }
   &__img {
      width: 7rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light;
      border-radius: 50%;
      margin-bottom: 3rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 1.5rem;
         width: 5rem;
         height: 5rem;
      }
   }
   &__icon {
      width: 2.6rem;
      height: 2.6rem;
      @media screen and (max-width: 666px) {
         width: 2rem;
         height: 2rem;
      }
      &_bolt {
         width: 1.8rem;
         height: 3rem;
         @media screen and (max-width: 666px) {
            width: 1.3rem;
            height: 2.5rem;
         }
      }
   }
}
/* ============================ product-range END ================================================================= */