@import '../app/variable.scss';
@import '../app/placeholder.scss';

/* ============================ vacancy START ================================================================= */
.preview {
   background-color: $bg-color;
   &__container {
      position: relative;
      margin: 0;
      padding: 0;
   }
   &__wrapper {
      padding: 7.4rem 0 10rem;
      @media screen and (max-width: 666px) {
         padding: 0;
         display: flex;
         flex-direction: column;
         justify-content: center;
         height: 28rem;
         max-width: 40rem;
      }
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
   }
   &__location {
      @extend %sub-title;
      @media screen and (max-width: 666px) {
         align-self: baseline;
      }
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
   }
   &__title {
      max-width: 57rem;
      width: 100%;
      @media screen and (min-width: 667px) and (max-width: 992px) {
         max-width: 42rem;
      }
      & > * {
         font-weight: 600;
         font-size: 4rem;
         line-height: 4.7rem;
         color: $title-color;
      }
   }
   &__box {
      display: flex;
      justify-content: space-between;
      max-width: 120rem;
      margin: 0 auto;
      padding: 0 1.5rem;
      @media screen and (max-width: 666px) {
         position: relative;
      }
   }
   &__img {
      max-width: 36rem;
      width: 100%;
      @media screen and (max-width: 666px) {
         position: absolute;
         top: 0;
         right: -16rem;
         height: 30.6rem;
         pointer-events: none;
      }
   }
}

.description {
   &__container {
      padding: 7rem 1.5rem 10rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   &__wp-editor {
      & > h2 {
         &:not(:last-child) {
            margin-bottom: 3rem;
            @media screen and (max-width: 666px) {
               margin-bottom: 2rem;
            }
         }
         font-weight: 700;
         font-size: 2.4rem;
         line-height: 2.8rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 1.8rem;
            line-height: 3.2rem;
         }
      }
      & > p {
         &:not(:last-child) {
            margin-bottom: 7rem;
            @media screen and (max-width: 666px) {
               margin-bottom: 3rem;
            }
         }
         max-width: 117rem;
         width: 100%;
         font-weight: 500;
         font-size: 2rem;
         line-height: 3.4rem;
         color: $title-color;
      }
      & > a {
         position: relative;
         font-style: normal;
         font-weight: 700;
         font-size: 1.6rem;
         line-height: 1.9rem;
         padding-left: 2rem;
         color: $accent;
         transition: color .25s $time-distribution-function;
         &:hover{
            color: $hover-accent-active;
            &::before {
               opacity: 0;
            }
            &::after {
               opacity: 1;
            }
         }
         &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 1.4rem;
            height: 1.4rem;
            transform: translate(0, -50%);
            background-image: url("img/sprite/arr-hover-back.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: opacity .25s $time-distribution-function;
            opacity: 0;
         }
         &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 1.4rem;
            height: 1.4rem;
            transform: translate(0, -50%);
            background-image: url("img/sprite/arr-back.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: opacity .25s $time-distribution-function;
            opacity: 1;
         }
      }
      & > div {
         &:not(:last-child) {
            margin-bottom: 3rem;
            @media screen and (max-width: 666px) {
               margin-bottom: 2rem;
            }
         }
         & > * {
            display: inline-block;
            padding: 1rem 1.6rem;
            background: #EDF0F3;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 1.9rem;
            text-transform: uppercase;
         }
      }
      ul, ol {
         max-width: 104rem;
         width: 100%;
         display: flex;
         justify-content: space-between;
         flex-wrap: wrap;
         margin: -2rem 0 0 -2rem;
         &:not(:last-child) {
            margin-bottom: 5rem;
            @media screen and (max-width: 666px) {
               margin-bottom: 3rem;
            }
         }
         @media screen and (max-width: 666px) {
            flex-direction: column;
         }
         & > * {
            position: relative;
            padding-left: 2.2rem;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.7rem;
            color: $title-color;
            margin: 2rem 0 0 2rem;
            flex: 0 1 calc(93% / 2 - 2rem);
            &::before {
               content: "";
               position: absolute;
               top: 1rem;
               left: 0;
               width: 1.4rem;
               height: 1.4rem;
               background-image: url("img/sprite/bolt.svg");
               background-position: center;
               background-repeat: no-repeat;
               background-size: contain;
            }
         }
      }
   }
}
/* ============================ vacancy END ================================================================= */
