@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/form/form.scss";

/* ============================ contact START ================================================================= */
.contact {
   background: $bg-color;
   &__container {
      position: relative;
   }
   &__wrapper {
      display: flex;
      justify-content: space-between;
      padding: 7.4rem 0 10rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 0;
         flex-direction: column;
         align-items: center;
      }
   }
   &__content {
      max-width: 57rem;
      width: 100%;
      @media screen and (min-width: 667px) and (max-width: 1023px) {
         margin: 0 1rem 0 0;
      }
      @media screen and (min-width: 1024px) {
         margin: 0 1rem 0 -3rem;
      }
      @media screen and (max-width: 666px) {
         margin: 0 0 3rem 0;
      }
   }
   &__title {
      &:not(:last-child) {
         margin-bottom: 3rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
      & > * {
         font-weight: 600;
         font-size: 4rem;
         line-height: 4.7rem;
         color: $title-color;
      }
   }
   &__location {
      @media screen and (min-width: 667px) {
         max-width: 21rem;
         width: 100%;
      }
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      & > * {
         font-weight: 700;
         font-size: 2.4rem;
         line-height: 3.4rem;
         color: $title-color;
      }
   }
   &__text {
      & > * {
         font-weight: 500;
         font-size: 2.4rem;
         line-height: 3.4rem;
         color: $title-color;
      }
   }
   &__img {
      position: absolute;
      bottom: 0;
      left: -31%;
      pointer-events: none;
      width: 79rem;
      height: 79.3rem;
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
}

.fancybox-content{
   padding: 0;
}
.fancybox-button{
   display: none;
}
/* ============================ contact END ================================================================= */