@import '../../app/variable.scss';
.service-quantum {
	&__icon{
		fill: #2596FF;
		&:hover,
		&:focus{
			fill: red;
		}
	}
	@media screen and (min-width: 1024px) {

		max-width: 170rem;
		width: 100%;
		margin: 0 auto;
	}

	.container {
		@media screen and (min-width: 1024px) {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
		}
	}

	&__img {
		max-height: 45rem;
		@media screen and (max-width: 666px) {
			min-height: 45rem;
			height: 1px;
		}

		@media screen and (min-width: 1024px) {
			max-width: 85rem;
			width: 100%;
			min-height: 45rem;
			margin-left: 3.5rem;
			position: relative;
		}

		& > * {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;

			@media screen and (min-width: 667px) {
				max-height: 45rem;
			}
			@media screen and (min-width: 1024px) {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&__title {
		margin-bottom: 2.5rem;

		& > * {
			font-weight: 700;
			font-size: 2.2rem;
			line-height: 3.1rem;

			@media screen and (min-width: 667px) {
				font-size: 3.5rem;
				line-height: 4.2rem;
			}
		}
	}

	&__wrapper {

		@media screen and (max-width: 1023px) {
			margin-top: 6rem;
			padding: 0 3rem 7rem;
		}

		@media screen and (min-width: 1024px) {
			max-width: 76.5rem;
			width: 100%;
			padding-left: 5rem;
		}

		& > * {
			font-size: 1.4rem;
			line-height: 2.8rem;
			margin-bottom: 2.5rem;

			@media screen and (min-width: 667px) {
				font-size: 1.5rem;
				line-height: 3rem;
			}
		}

		.button {
			padding: 1.8rem 2rem;
			transition: background-color .25s $time-distribution-function;
			flex: 0 0 auto;

			&:hover,
			&:focus {
			}
		}
	}
}
